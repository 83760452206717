class SwipperCustom {
  constructor() {
    this._swipperFooter();
    this._swipperRelacionadas();
    this._swipperHome();
    this._swipperSlider();
  }
  _swipperFooter() {

    var swiper = new Swiper(".swiperFooter", {
      slidesPerView: 3,
      spaceBetween: 0,
      grabCursor: true,
      loop: false,
      pagination: {
        el: ".swiper-pagination",
      },
      breakpoints: {
        640: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 5,
        },
        1024: {
          slidesPerView: 6,
        },
      },
    });

  }
  _swipperRelacionadas() {
    var swiper = new Swiper(".swiperRelacionadas", {
      slidesPerView:1.1,
      spaceBetween: 15,
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
      },
      
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1230: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
    });

  }
  _swipperHome() {
    var swiper = new Swiper(".swiperHome", {
      slidesPerView: "auto",
      spaceBetween: 20,
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
      },
      
     
    });

  }
  _swipperSlider() {
    var swiper = new Swiper(".swiperSlider", {
      slidesPerView: 1,
      spaceBetween: 0,
      grabCursor: true,
      loop: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".slider__arrow-next",
        prevEl: ".slider__arrow-prev",
      },
      
     
    });

  }

}