class ActiveSection {
    constructor() {
        this._stageSection();
        //this._topSection();

    }
    _topSection() {


    }

    _stageSection() {
        if ($("body").hasClass("page-template-inicio")) {
            $(".menu-item.inicio").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-8")) {
            $(".menu-item.informa").addClass("current-menu-item");
        }
        if ($("body").hasClass("parent-category-8")) {
            $(".menu-item.informa").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-5")) {
            $(".menu-item.mundo").addClass("current-menu-item");
        }
        if ($("body").hasClass("parent-category-5")) {
            $(".menu-item.mundo").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-7")) {
            $(".menu-item.data").addClass("current-menu-item");
        }
        if ($("body").hasClass("parent-category-7")) {
            $(".menu-item.data").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-3")) {
            $(".menu-item.market").addClass("current-menu-item");
        }
        if ($("body").hasClass("parent-category-3")) {
            $(".menu-item.market").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-4")) {
            $(".menu-item.compliance").addClass("current-menu-item");
        }
        if ($("body").hasClass("parent-category-4")) {
            $(".menu-item.compliance").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-11")) {
            $(".menu-item.opina").addClass("current-menu-item");
        }
        if ($("body").hasClass("parent-category-11")) {
            $(".menu-item.opina").addClass("current-menu-item");
        }
        if ($("body").hasClass("page-template-somos")) {
            $(".menu-item.somos").addClass("current-menu-item");
        }
         if ($("body").hasClass("esginforma")) {
            $(".menu-item.data").addClass("current-menu-item");
        }
         if ($("body").hasClass("esgmundo")) {
            $(".menu-item.data").addClass("current-menu-item");
        }
        if ($("body").hasClass("esgdata")) {
            $(".menu-item.data").addClass("current-menu-item");
        }
        if ($("body").hasClass("esgmarket")) {
            $(".menu-item.data").addClass("current-menu-item");
        }
        if ($("body").hasClass("esgcompliance")) {
            $(".menu-item.data").addClass("current-menu-item");
        }
        if ($("body").hasClass("esgopina")) {
            $(".menu-item.data").addClass("current-menu-item");
        }
    }
}